<template>
  <div style="position: relative">
    <!--    <div class="block-title">-->
    <!--      <div class="fl"><i class="el-icon-arrow-left back" @click="goBack"></i>订单详情</div>-->
    <!--    </div>-->
    <div class="btn-container" v-if="activeName === '3'">
      <el-button size="small" type="primary" @click="handleAddRemarkDialogVisible(true)">添加备注</el-button>
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="changeTab">
      <el-tab-pane label="基本信息" name="1">
        <el-form label-position="left" label-width="110px" class="left_10 common-form show-form" :model="baseForm">
          <el-form-item label="订单号：" prop="orderNo">
            {{ baseForm.orderNo || '-' }}
          </el-form-item>
          <el-form-item label="订单状态：" prop="status">
            {{ baseForm.status === 0 ? '下单待支付' : baseForm.status === 1 ? '已支付待取货' : baseForm.status === 2 ? '租赁中' : baseForm.status === 3 ? '已归还待确认' : baseForm.status === 4 ? '归还结算完成' : baseForm.status === 5 ? '订单关闭' : '-' }}
          </el-form-item>
          <el-form-item label="下单时间：" prop="createTime">
            {{ baseForm.createTime || '-' }}
          </el-form-item>
          <el-form-item label="完成时间：" prop="endTime">
            {{ baseForm.endTime || '-' }}
          </el-form-item>
          <el-form-item label="租赁时间：" prop="pickTime">
            {{ baseForm.pickTime || '-' }}
          </el-form-item>
          <el-form-item label="归还时间：" prop="returnTime">
            {{ baseForm.returnTime || '-' }}
          </el-form-item>
          <el-form-item label="订单异常：" prop="abnormal">
            {{ baseForm.abnormal ? '有' : '无' }}
          </el-form-item>
          <el-form-item label="单位：" prop="department">
            {{ baseForm.department || '-' }}
          </el-form-item>
          <el-form-item label="位置：" prop="position">
            {{ baseForm.position || '-' }}
          </el-form-item>
          <el-form-item label="设备id：" prop="id">
            {{ baseForm.id || '-' }}
          </el-form-item>
          <el-form-item label="设备号：" prop="imei">
            {{ baseForm.imei || '-' }}
          </el-form-item>
          <el-form-item label="订单主货道：" prop="mainWayNo">
            {{ baseForm.mainWayNo || '-' }}
          </el-form-item>
          <el-form-item label="货道状态：" prop="mainStatus">
            {{ baseForm.mainStatus === 0 ? '可租赁' : baseForm.mainStatus === 1 ? '已分配' : baseForm.mainStatus === 2 ? '租赁中' : baseForm.mainStatus === 3 ? '归还中' : baseForm.mainStatus === 4 ? '消毒中' : baseForm.mainStatus === 5 ? '冷却中' : baseForm.mainStatus === 6 ? '不可用' : '-' }}
          </el-form-item>
          <el-form-item label="锁状态：" prop="mainDoorStatus">
            {{baseForm.mainDoorStatus === 1 ? '锁开' : baseForm.mainDoorStatus === 0 ? '锁关' : '-' }}
          </el-form-item>
          <el-form-item label="货状态：" prop="mainHasGoods">
            {{ baseForm.mainHasGoods === 1 ? '有床' : baseForm.mainHasGoods === 0 ? '无床' : baseForm.mainHasGoods === 2 ? 'RFID异常' : '-' }}
          </el-form-item>
          <el-form-item label="订单主商品：" prop="mainType">
            {{ baseForm.mainType === 0 ? '陪护床' : baseForm.mainType === 1 ? '床单' : '-' }}
          </el-form-item>
          <el-form-item label="副货道商品：" prop="auxiliaryType">
            {{ baseForm.auxiliaryType === 0 ? '陪护床' : baseForm.auxiliaryType === 1 ? '床单' : '-' }}
          </el-form-item>
          <el-form-item label="副货道状态：" prop="hasGoods">
            {{ baseForm.hasGoods === 0 ? '异常' : baseForm.hasGoods === 1 ? '可用' : '-' }}
          </el-form-item>
          <el-form-item label="用户名：" prop="userName">
            {{ baseForm.userName || '-' }}
          </el-form-item>
          <el-form-item label="手机号：" prop="telephone">
            {{ baseForm.telephone || '-' }}
          </el-form-item>
        </el-form>
        <div class="btn-list left_10">
          <el-button v-if="baseForm.status !== 5 && baseForm.status!==6" style="padding: 12px 50px" type="primary" @click="handleCloseOrderDialogVisible(true)">关闭订单
          </el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="财务信息" name="2" v-if="this.flag">
        <el-form label-position="left" label-width="110px" class="left_10 common-form show-form" :model="financeInfo">
          <el-form-item label="租赁时间：" prop="pickTime">
            {{ financeInfo.pickTime || '-' }}
          </el-form-item>
          <el-form-item label="归还时间：" prop="returnTime">
            {{ financeInfo.returnTime || '-' }}
          </el-form-item>
          <el-form-item label="已租时长：" prop="duration">
            {{ translationTime(financeInfo.duration) }}
          </el-form-item>
          <el-form-item label="夜间：" prop="busyDuration">
            {{ translationTime(financeInfo.busyDuration) }}
          </el-form-item>
          <el-form-item label="日间：" prop="leisureDuration">
            {{ translationTime(financeInfo.leisureDuration) }}
          </el-form-item>
          <el-form-item label="押金状态：" prop="depositStatus">
            {{ financeInfo.depositStatus === 0 ? '待支付押金' : financeInfo.depositStatus === 1 ? '已支付押金' : financeInfo.depositStatus === 2 ? '待退还押金' : financeInfo.depositStatus === 3 ? '已返还押金' : '-' }}
          </el-form-item>
          <el-form-item label="支付时间：" prop="depositTime">
            {{ financeInfo.depositTime || '-' }}
          </el-form-item>
          <el-form-item label="押金金额：" prop="depositAmount">
            {{ financeInfo.depositAmount || '-' }}元
            <el-button size="small" type="primary" class="left_30" v-if="financeInfo.depositStatus !== 0" @click="handleStartRefundDialogVisible(true,false,0)">退款</el-button>
          </el-form-item>
          <el-form-item label="已退押金：" prop="depositAmount">
            {{ financeInfo.alreadyReturn || '-' }}元
          </el-form-item>
          <el-form-item label="押金支付单号：" prop="depositAmountPaymentNo">
            {{ financeInfo.depositAmountPaymentNo || '-' }}
          </el-form-item>
          <el-form-item label="租金状态：" prop="rentalStatus">
            {{ financeInfo.rentalStatus === 0 ? '未开始计费' : financeInfo.rentalStatus === 1 ? '开始租金计费' : financeInfo.rentalStatus === 2 ? '待支付租金' : financeInfo.rentalStatus === 3 ? '已支付租金' : '-' }}
          </el-form-item>
          <el-form-item label="支付时间：" prop="rentalTime">
            {{ financeInfo.rentalTime || '-' }}
          </el-form-item>
          <el-form-item label="租金金额：" prop="rentalAmount">
            {{ financeInfo.rentalAmount || '-' }}元
            <el-button size="small" type="primary" class="left_30" v-if="financeInfo.rentalStatus === 3" @click="handleStartRefundDialogVisible(true,false,1)">退款</el-button>
          </el-form-item>
          <el-form-item label="已退租金：" prop="rentalAmount">
            {{ financeInfo.alreadyReturnRent || '-' }}元
          </el-form-item>
          <el-form-item label="日间金额：" prop="leisureOrderPrice">
            {{ financeInfo.leisureOrderPrice || '-' }}元
            <!--<el-button size="small" type="primary" class="left_30" v-if="financeInfo.rentalStatus === 3" @click="handleStartRefundDialogVisible(true,false,2)">退款</el-button>-->
          </el-form-item>
          <el-form-item label="夜间金额：" prop="busyOrderPrice">
            {{ financeInfo.busyOrderPrice || '-' }}元
            <!--<el-button size="small" type="primary" class="left_30" v-if="financeInfo.rentalStatus === 3" @click="handleStartRefundDialogVisible(true,false,3)">退款</el-button>-->
          </el-form-item>
          <el-form-item label="租金支付单号：" prop="rentalAmountPaymentNo">
            {{ financeInfo.rentalAmountPaymentNo || '-' }}
          </el-form-item>
        </el-form>
        <!--        <div class="btn-list left_10">-->
        <!--          <el-button v-if="financeInfo.rentalStatus === 1" style="padding: 12px 50px" type="primary" @click="handleStartRefundDialogVisible(true)">发起退款-->
        <!--          </el-button>-->
        <!--        </div>-->
      </el-tab-pane>
      <el-tab-pane label="订单记录" name="3">
        <div class="record-list">
          <div class="record-item" v-for="(item,index) in recordTableData" :key="index">
            <div class="time">{{ item.recordTime }}</div>
            <div class="record">{{ item.content }}</div>
          </div>
        </div>
      </el-tab-pane>
      <!--      <el-tab-pane label="退款申请" name="4">-->
      <!--        <div class="table-container">-->
      <!--          <el-table-->
      <!--              class="table"-->
      <!--              height="100%"-->
      <!--              v-loading="refundTableLoading"-->
      <!--              :data="refundTableData">-->
      <!--            <el-table-column-->
      <!--                label="订单号"-->
      <!--                prop="orderId"-->
      <!--                width="100"-->
      <!--                align="center"-->
      <!--                header-align="center"-->
      <!--            >-->
      <!--              <template slot-scope="scope">-->
      <!--                {{ scope.row.orderId }}-->
      <!--              </template>-->
      <!--            </el-table-column>-->
      <!--            <el-table-column-->
      <!--                label="支付单号"-->
      <!--                prop="paymentNo"-->
      <!--                align="center"-->
      <!--                header-align="center"-->
      <!--                :formatter="commonFormatter"-->
      <!--            >-->
      <!--            </el-table-column>-->
      <!--            <el-table-column-->
      <!--                label="申请金额"-->
      <!--                prop="refundAmount"-->
      <!--                width="150"-->
      <!--                align="center"-->
      <!--                header-align="center"-->
      <!--                :formatter="commonFormatter">-->
      <!--            </el-table-column>-->
      <!--            <el-table-column-->
      <!--                label="申请原因"-->
      <!--                prop="refundCause"-->
      <!--                align="center"-->
      <!--                header-align="center"-->
      <!--                :formatter="commonFormatter"-->
      <!--            >-->
      <!--            </el-table-column>-->
      <!--            <el-table-column-->
      <!--                label="状态"-->
      <!--                width="100"-->
      <!--                align="center"-->
      <!--                header-align="center"-->
      <!--                prop="status"-->
      <!--                :formatter="commonFormatter"-->
      <!--            >-->
      <!--              <template slot-scope="{row}">-->
      <!--                {{ row.status === 0 ? '待处理' : row.status === 1 ? '已退款' : row.status === 2 ? '已驳回' : '-' }}-->
      <!--              </template>-->
      <!--            </el-table-column>-->
      <!--            <el-table-column-->
      <!--                label="申请时间"-->
      <!--                width="180"-->
      <!--                align="center"-->
      <!--                header-align="center"-->
      <!--                prop="createTime"-->
      <!--                :formatter="commonFormatter"-->
      <!--            >-->
      <!--            </el-table-column>-->
      <!--            <el-table-column-->
      <!--                label="退款时间"-->
      <!--                width="180"-->
      <!--                align="center"-->
      <!--                header-align="center"-->
      <!--                prop="refundTime"-->
      <!--                :formatter="commonFormatter"-->
      <!--            >-->
      <!--            </el-table-column>-->
      <!--            <el-table-column-->
      <!--                label="申请人"-->
      <!--                prop="name"-->
      <!--                width="100"-->
      <!--                align="center"-->
      <!--                header-align="center"-->
      <!--                :formatter="commonFormatter"-->
      <!--            >-->
      <!--            </el-table-column>-->
      <!--            <el-table-column-->
      <!--                label="操作"-->
      <!--                width="100"-->
      <!--                align="center"-->
      <!--                header-align="center"-->
      <!--            >-->
      <!--              <template slot-scope="{row}">-->
      <!--                <el-button type="text" :disabled="row.status !== 0" @click="refund">退款</el-button>-->
      <!--                <el-button type="text" :disabled="row.status !== 0" @click="rejectOrderRefund(row.id)">驳回</el-button>-->
      <!--              </template>-->
      <!--            </el-table-column>-->
      <!--          </el-table>-->
      <!--        </div>-->
      <!--      </el-tab-pane>-->
    </el-tabs>
    <AddRemark :orderId="orderNo" :addRemarkDialogVisible="addRemarkDialogVisible" @close="handleAddRemarkDialogVisible"></AddRemark>
    <CloseOrder :orderId="orderNo" :closeOrderDialogVisible="closeOrderDialogVisible" @close="handleCloseOrderDialogVisible"></CloseOrder>
    <StartRefund :paymentNo="paymentNo" :startRefundDialogVisible="startRefundDialogVisible" :type="refundType"  @close="handleStartRefundDialogVisible"></StartRefund>
  </div>
</template>

<script>
  import AddRemark from "@/views/order/AddRemark";
  import CloseOrder from "@/views/order/CloseOrder";
  import StartRefund from "@/views/order/StartRefund";
  import tableUse from "@/mixins/tableUse";
  import {resetMenuList} from "@/assets/js/common";
  export default {
    name: "OrderDetail",
    mixins:[tableUse],
    components: {
      AddRemark,
      CloseOrder,
      StartRefund
    },
    created() {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      let roles = userInfo.roles
      for (let i = 0; i < roles.length; i++) {
        if (roles[i]==='1193757683205607426'){
          this.flag = true
        }
      }
    },
    computed:{
      userInfo(){
        return window.localStorage.getItem('userInfo') && JSON.parse(window.localStorage.getItem('userInfo'))
      },
      orderNo(){
        return this.$route.query.orderNo
      },
    },
    data() {
      return {
        recordTableData: [],
        baseForm:{},
        financeInfo:{},
        addRemarkDialogVisible: false,
        closeOrderDialogVisible: false,
        startRefundDialogVisible: false,
        refundType:-1,
        paymentNo:'',
        activeName: '1',
        refundTableLoading:false,
        refundTableData:[
        ],
        recordTableLoading:false,
        flag:false
      }
    },
    methods: {
      goBack() {
        this.$router.back();
      },
      changeTab(){
        switch (this.activeName){
          case "1":{
            this.getOrderBaseInfo();
            break;
          }
          case '2':{
            this.getFinanceInfo();
            break;
          }
          case '3':{
            this.getRecordTableList();
            break;
          }
          case '4':{
            this.getRefundTableList();
            break;
          }
        }
      },
      handleAddRemarkDialogVisible(addRemarkDialogVisible, isRe) {
        this.addRemarkDialogVisible = addRemarkDialogVisible;
        if(isRe){
          this.getRecordTableList();
        }
      },
      handleCloseOrderDialogVisible(closeOrderDialogVisible,isRe) {
        this.closeOrderDialogVisible = closeOrderDialogVisible;
        if(isRe){
          this.getOrderBaseInfo();
        }
      },
      handleStartRefundDialogVisible(startRefundDialogVisible,isRe,type) {
        this.startRefundDialogVisible = startRefundDialogVisible;
        this.refundType = type || type === 0 ? type : -1;
        this.paymentNo = '';
        if(this.refundType === 0) this.paymentNo = this.financeInfo.depositAmountPaymentNo;
        if(this.refundType === 1) this.paymentNo = this.financeInfo.rentalAmountPaymentNo;
        if(this.refundType === 2) this.paymentNo = this.financeInfo.rentalAmountPaymentNo;
        if(this.refundType === 3) this.paymentNo = this.financeInfo.rentalAmountPaymentNo;
        if(isRe){
          this.getFinanceInfo();
        }
      },
      refund() {
        this.$prompt('<p><i class="el-icon-warning" style="margin-right: 5px;color: #e6a23c;font-size: 15px;"></i>确认退款前需输入登录密码校验</p>', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          closeOnPressEscape:false,
          inputType:'password',
          dangerouslyUseHTMLString: true
        }).then(({ value }) => {
          console.log(value)
        }).catch(() => {

        });
      },
      rejectOrderRefund(id){
        this.$confirm('此操作将驳回此条退款申请, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.post("/sys-api/order-api/reject",{id},(res) => {
            if(res.code === '100'){
              this.$message.success("驳回退款申请成功");
              this.getRefundTableList();
            }
          })
        });
      },
      getOrderBaseInfo(){
        this.$axios.get("/sys-api/order-api/getOrderId",{orderNo:this.orderNo},(res) => {
          if(res.code === '100'){
            this.baseForm = res.data || {};
          }
        })
      },
      translationTime(time){
        if(!time) return '0分钟';
        let hour = parseInt(time / 60);
        let min = time % 60;
        if(hour && min) return hour + '小时' + min + '分钟';
        else if(!hour && min) return min + '分钟';
        else if(hour && !min) return hour + '小时';
        else return '0分钟';
      },
      getFinanceInfo(){
        this.$axios.get("/sys-api/order-api/getAccountantOrder",{orderNo:this.orderNo},(res) => {
          if(res.code === '100'){
            this.financeInfo = res.data ||  {};
          }
        })
      },
      getRecordTableList(){
        this.recordTableLoading = true;
        let formData = {
          orderId:this.orderNo
        }
        this.$axios.post("/sys-api/order-api/orderRecord",formData,(res) => {
          this.recordTableLoading = false;
          if(res.code === '100'){
            this.recordTableData = res.data;
          }
        })
      },
      getRefundTableList(){
        this.refundTableLoading = true;
        this.$axios.get("/sys-api/order-api/refundList",{orderNo:this.orderNo},(res) => {
          this.refundTableLoading = false;
          if(res.code === '100'){
            this.refundTableData = res.data;
          }
        })
      },
    },
    mounted() {
      this.getOrderBaseInfo();

    }
  }
</script>

<style scoped lang="scss">
  .small-input {
    width: 300px;
  }

  .btn-list {
    margin-top: 20px;
    margin-left: 100px;
  }

  .table-container {
    height: 100%;
    padding: 0;
  }

  .table-container-record{
    height: calc(100% - 50px);
  }

  .el-tabs {
    padding: 10px;
    height: calc(100% - 20px);
  }

  ::v-deep .el-tabs__content {
    height: calc(100% - 56px - 15px);
    overflow: auto;
  }

  .el-tab-pane {
    height: 100%;
  }
  .btn-container{
    position: absolute;
    top: 13px;
    right: 20px;
    z-index: 2;
  }
  .record-list{
    overflow: auto;
    .record-item{
      overflow: hidden;
      margin-bottom: 10px;
      &>div{
        float: left;
        font-size: 15px;
        color: #666;
      }
      .time{
        margin-right: 20px;
      }
    }
  }
</style>
